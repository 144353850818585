<template>
    <div class="bg-white">
        <div class="d-flex justify-content-between align-items-center mb-3">
            <div>
                <h1 class="h4 mb-0">Accounts</h1>
            </div>
        </div>
        <form class="bg-light p-3" novalidate @submit.prevent="submitFilter">
            <div class="row">
                <div class="col-md-6 col-xxl-5 mb-3">
                    <TextInput
                        label="Account Code"
                        v-model="account_id"
                        :v="v$.account_id"
                    />
                </div>
                <div class="col-md-6 col-xxl-5 mb-3">
                    <DropdownInput
                        label="Status"
                        v-model="account_status"
                        :v="v$.account_status"
                        :options="statuses"
                    />
                </div>
                <div class="col-md-4 col-xxl-2 mb-3">
                    <label class="form-label">&nbsp;</label>
                    <div class="d-grid">
                        <button class="btn btn-primary me-1" type="submit">Filter</button>
                    </div>
                </div>
            </div>
        </form>
        <br>
        <ul v-if="accountsData && accountsData.data.length" class="order-list mb-4">
            <li class="header-row">
                <div class="row align-items-center">
                    <div class="col">
                        <strong>Code</strong>
                    </div>
                    <div class="col">
                        <strong>Dentist</strong>
                    </div>
                    <div class="col"><strong>Status</strong></div>
                    <div class="col d-flex justify-content-end align-items-center"></div>
                </div>
            </li>
            <li v-for="account of accountsData.data" :key="account.id">
                <div class="row align-items-center">
                    <div class="col">
                        <div class="">
                            <strong>{{account.code}}</strong>
                        </div>
                    </div>
                    <div class="col">
                        <div class="">
                            {{account.dentist.first_name}} {{account.dentist.surname}}
                        </div>
                    </div>
                    <div class="col">
                        <span class="badge" :class="getStatusBackground(account.status)">{{getStatusName(account.status)}}</span>
                    </div>
                    <div class="col d-flex justify-content-end align-items-center">
                        <div class="text-center"><router-link class="btn btn-secondary" :to="{ name: 'Account', params: { id: account.id }}">View</router-link></div>
                    </div>
                </div>
            </li>
        </ul>

        <div v-if="accountsData && !accountsData.data.length">
            No Accounts
        </div>
        <Pagination
            v-if="accountsData && accountsData.data.length"
            v-bind:currentPage="currentPage"
            v-bind:lastPage="lastPage"
            viewComponent="Accounts"
        />
    </div>
</template>

<style scoped lang="scss">
.order-list
{
    list-style: none;
    padding: 0px;
    margin: 0px;

    > li
    {
        border-top: 1px solid #EEEEEE;
        padding: 10px 0px;

        strong
        {
            color: $swift-dark-grey;
        }
    }

    .header-row
    {
        border-top: 0px;
    }
}
</style>

<script>
    import api from "@/services/api";
    import TextInput from '@/components/TextInput.vue';
    import DropdownInput from '@/components/DropdownInput.vue';
    import Pagination from '@/components/Pagination.vue';
    import useVuelidate from "@vuelidate/core";

    export default {
        setup () {
            return {
                v$: useVuelidate()
            }
        },
        data() {
            return {
                accountsData: null,
                account_id: '',
                account_status: '2',
                statuses: [{id:1, label:'All'}, {id:2, label:'Current'}, {id:3, label:'Closed'}],
            }
        },
        validations() {
            return {
                account_id: {},
                account_status: {},
            }
        },
        components: {
            TextInput,
            DropdownInput,
            Pagination,
        },
        computed: {
            currentPage () {
                var pageParam = this.$route.query.page;
                return typeof pageParam === 'undefined' ? 1 : parseInt(pageParam);
            },
            lastPage () {
                if(this.accountsData !== null)
                {
                    return parseInt(this.accountsData.meta.last_page);
                }

                return null;
            },
        },
        methods: {
            moveUp() {
                const html = document.querySelector('html')
                var scrollBehavior = html.style.scrollBehavior;
                html.style.scrollBehavior = 'auto'
                window.scrollTo({
                    top: 0,
                });
                setTimeout(() => {
                    html.style.scrollBehavior = scrollBehavior;
                }, 100);
            },
            getStatusBackground: function(status) {
                switch(status)
                {
                    case 10: return 'bg-success';
                    case 20: return 'bg-warning';
                    case 100: return 'bg-danger';
                    default: return 'bg-secondary';
                }
            },
            getStatusName: function(status) {
                switch(status)
                {
                    case 10: return 'Active';
                    case 20: return 'Inactive';
                    case 100: return 'Left';
                    default: return 'Unknown';
                }
            },
            fetchAccounts: function() {
                if(!this.$store.state.currentPracticeId)
                {
                    this.accountsData = null;
                    return;
                }

                this.moveUp();

                var params = {
                    practice_id: this.$store.state.currentPracticeId,
                    page: this.currentPage,
                };

                if(this.account_id.trim().length)
                {
                    params.account_id = this.account_id;
                }

                if(this.account_status.trim().length)
                {
                    params.account_status = this.account_status;
                }

                api
                    .get('/api/v1/accounts', {
                        params: params
                    })
                    .then((response) => {
                        this.accountsData = response.data;
                    })
                    .catch(() => {
                        console.log('Catch accounts error');
                    });
            },
            submitFilter: async function()
            {
                var isValid = await this.v$.$validate();

                if(isValid)
                {
                    var query = {};

                    if(this.account_id.trim().length)
                    {
                        query.account_id = this.account_id.trim();
                    }

                    if(this.account_status.trim().length)
                    {
                        query.account_status = this.account_status.trim();
                    }

                    this.$router.replace({ name: 'Accounts', query: query});
                    this.fetchAccounts();
                }
                else
                {
                    console.log('Validation errors', this.v$.$errors.map((error) => { return error.$property + ': ' + error.$message}));
                }

                return false;
            },
            updateFromQuery: function() {
                this.account_id = '';
                this.account_status = '';

                if(typeof this.$route.query.account_id !== 'undefined')
                {
                    this.account_id = this.$route.query.account_id;
                }

                if(typeof this.$route.query.account_status !== 'undefined')
                {
                    this.account_status = this.$route.query.account_status;
                }
            },
        },
        mounted () {
            this.fetchAccounts();
        },
        watch: {
            currentPage() {
                this.fetchAccounts();
            },
            '$store.state.currentPracticeId': function() {
                this.fetchAccounts();
            }
        },
    }

</script>
